import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import heatPumps from "../../assets/images/heat_pump_condenser.webp"

const HeatPumps = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Heat Pumps")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Heat Pumps - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Switch to a heat pump for energy-efficient heating and cooling! Enjoy year-round comfort, lower bills, and a smaller carbon footprint." />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}heat-pumps`} />
            </Helmet>
            <Showcase info={{
                title: "Year-Round Comfort with Heat Pumps!",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Switch to a heat pump for energy-efficient heating and cooling! Enjoy year-round comfort, lower bills, and a smaller carbon footprint.",
                image: {
                    src: heatPumps,
                    alt: "",
                    width: 800,
                    height: 392
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Heat pump systems are a great option because they offer eco-friendly two-in-one comfort. There’s no other type of system out there that can heat and cool from one unit. If you want to make sure that your heat pump gets the best quality service, we recommend relying on us as your installer.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We can perform heat pump maintenance, installation, replacement, and repair work. We’re able to do it all because our central focus is customer satisfaction. We’re known as the best in New Jersey, and we work hard to uphold this reputation.</p>
        </main>
    )
}

export default HeatPumps;