import React from 'react'
import { getCurrentLocation } from "../tools";
import { Link } from "react-router-dom"
import LocationRouterLink from "./LocationRouterLink"
import siteData from "../data"

const Footer = () => {
    const currentLocation = getCurrentLocation();

  return (
    <div className='w-full print:hidden bg-slate-900 text-gray-300 py-y px-2'>
        <div className='max-w-[1240px] space-x-3 mx-auto grid grid-cols-2 md:grid-cols-4 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2 border-b border-gray-200'>Services</h6>
                <ul className='grid grid-cols-1'>
                    {siteData.services.slice(0, 3).map((service) => (
                        <li key={service.name} className='py-1 cursor-pointer text-sm'>
                            <LocationRouterLink to={`/${service.slug}`} currentLocation={currentLocation}>{service.name}</LocationRouterLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 border-b border-gray-200'>Support</h6>
                <ul>
                    <li className='py-1 cursor-pointer text-sm'>
                        <LocationRouterLink to="/faq" currentLocation={currentLocation} >FAQ</LocationRouterLink>
                    </li>
                    <li className='py-1 cursor-pointer text-sm'>
                    <LocationRouterLink to="/contactus" currentLocation={currentLocation}>Contact</LocationRouterLink>
                    </li>
                    <li className='py-1 cursor-pointer text-sm'>
                        <a href="tel:+17322810003">(732) 281 0003</a>
                    </li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 border-b border-gray-200'>Company</h6>
                <ul>
                    <li className='py-1 cursor-pointer text-sm'>
                        <LocationRouterLink to="/about" currentLocation={currentLocation}>About</LocationRouterLink>
                    </li>
                    <li className='py-1 cursor-pointer text-sm'>
                        <LocationRouterLink to="/jobs" currentLocation={currentLocation}>Jobs</LocationRouterLink>
                    </li>
                    <li className='py-1 text-sm'>Owner: Christopher K. Madden</li>
                    <li className='py-1 text-sm'>NJ Master HVACR Contractor License #19HC00030500.</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 border-b border-gray-200'>Legal</h6>
                <ul>
                    <li className='py-1 cursor-pointer text-sm'>
                        <Link to="/cookies-policy">Cookies Policy</Link>
                    </li>
                    <li className='py-1 cursor-pointer text-sm'>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                </ul>
            </div>

            {/* <div className='col-span-2 pt-8 md:pt-2'>
                <p className='font-bold uppercase'>Subscribe to our newsletter</p>
                <p className='py-4'>The latest news, articles, and resources, sent to your inbox weekly.</p>
                <form className='flex flex-col sm:flex-row'>
                    <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter email..'/>
                    <button className='p-2 mb-4'>Subscribe</button>
                </form>
            </div> */}
        </div>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <div className='text-left'>
            <p className='pt-4'>{new Date().getFullYear()} A.A. Richards Heating, Cooling, & Plumbing, LLC. All rights reserved</p>
            <a className='text-xs' href="https://www.thebosapps.com/?utm_source=aarichardshvac">Powered by BOSApps</a>
        </div>

        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
            {siteData.socialMedia.map((media) => (
                <a target='_black' key={media.name} title={media.name} href={media.url}>{media.icon}</a>
            ))}
        </div>
        </div>
    </div>
  )
}

export default Footer