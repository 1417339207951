import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import siteData from "../data"
import LocationRouterLink from "../components/LocationRouterLink"
import { getCurrentLocation } from "../tools"

const AllInOne = () => {
  const currentLocation = getCurrentLocation()

  return (
    <div name='services' className='w-full my-32'>
      <div className='max-w-[1240px] mx-auto px-2'>
        <h2 className='text-5xl font-bold text-center'>Our Services</h2>
        <p className='text-lg md:text-2xl max-w-[1000px] m-auto py-8 text-gray-500 text-center'>
          At A.A. Richards Heating, Cooling, & Plumbing, we take pride in offering a full spectrum of HVAC, plumbing, and home comfort services. Our team of certified technicians is committed to delivering expert solutions tailored to your specific needs.
        </p>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 pt-4'>
          {siteData.services.map((service) => (
            <div key={service.name} className='flex'>
            <div>
              <CheckIcon className='w-7 mr-4 text-green-600' />
            </div>
            <div>
              <h3 className='font-bold text-lg'>{service.name}</h3>
              <p className='text-md md:text-lg pt-2 pb-4'>{service.description}</p>
              {service.buttonName && <LocationRouterLink to={service.buttonPath} currentLocation={currentLocation}
                className='button text-sm md:text-md py-1 px-4'>{service.buttonName}</LocationRouterLink>}
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
