import React, { useState } from 'react';
import siteData from "../data";
import { getCurrentLocation } from "../tools";
import logo from "../assets/logo.webp"
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import LocationRouterLink from "../components/LocationRouterLink"

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const handleClose =()=> setNav(!nav)
    const currentLocation = getCurrentLocation();

  return (
    <div className='w-screen print:hidden h-[80px] z-10 bg-zinc-200 fixed top-0 drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
        <LocationRouterLink to="/" onClick={() => setNav(false)} currentLocation={currentLocation}>
          <img src={logo} alt="/" width={70} height={70} />
        </LocationRouterLink>
        
          <ul className='hidden md:flex'>
          <li><LocationRouterLink to="/" currentLocation={currentLocation}>Home</LocationRouterLink></li>
          <li><LocationRouterLink to="/about" currentLocation={currentLocation}>About</LocationRouterLink></li>
          <li><LocationRouterLink to="/contactus" currentLocation={currentLocation}>Contact</LocationRouterLink></li>
          <li><LocationRouterLink to="/services" currentLocation={currentLocation}>Services</LocationRouterLink></li>
          </ul>
        </div>

        <div className='hidden md:flex pr-4'>
        <div className='flex space-x-3 items-center mr-10 text-2xl'>
            {siteData.socialMedia.map((media) => (
                <a target='_black' className='text-blue-500' key={media.name} title={media.name} href={media.url}>{media.icon}</a>
            ))}
        </div>
          {/* <button className='border-none bg-transparent text-black mr-4'>
            Sign In
          </button>
          <button className='px-8 py-3'>Sign Up</button> */}
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5 cursor-pointer' /> : <XIcon className='w-5 cursor-pointer' />}
          
        </div>
      </div>

      <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
      <li><LocationRouterLink to="/" className='border-b-2 border-zinc-300 w-full' onClick={handleClose} currentLocation={currentLocation}>Home</LocationRouterLink></li>
          <li><LocationRouterLink to="/about" className='border-b-2 border-zinc-300 w-full' onClick={handleClose} currentLocation={currentLocation}>About</LocationRouterLink></li>
          <li><LocationRouterLink to="/contactus" className='border-b-2 border-zinc-300 w-full' onClick={handleClose} currentLocation={currentLocation}>Contact</LocationRouterLink></li>
          <li><LocationRouterLink to="/services" className='border-b-2 border-zinc-300 w-full' onClick={handleClose} currentLocation={currentLocation}>Services</LocationRouterLink></li>
        <div className='flex flex-col my-4'>
          <div className='flex space-x-3 mr-10 pt-4 text-2xl'>
              {siteData.socialMedia.map((media) => (
                  <a target='_black' className='text-blue-500' key={media.name} title={media.name} href={media.url}>{media.icon}</a>
              ))}
          </div>
            {/* <button className='bg-transparent text-indigo-600 px-8 py-3 mb-4'>Sign In</button>
            <button className='px-8 py-3'>Sign Up</button> */}
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
