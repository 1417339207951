import { useEffect, useState } from 'react';
import Helmet from "react-helmet"
import siteData from "../data";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { getCurrentLocation } from "../tools";

const FAQ = () => {
    const currentLocation = getCurrentLocation();
    const [openFAQ, setOpenFAQ] = useState(null); // Store the currently open FAQ ID
    const [search, setSearch] = useState("");

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    const toggleFAQ = (id) => {
        setOpenFAQ((prev) => (prev === id ? null : id)); // Toggle the FAQ; close if the same one is clicked again
    };

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQ - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Here are common questions from our Tom's River customers to help you make informed decisions for your home. We hope you find this helpful!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}faq`} />
            </Helmet>
            <section className='w-full my-32'>
                <div className='max-w-[1240px] mx-auto px-2'>
                    <h1 className='text-5xl font-bold text-center'>Frequently Asked Questions</h1>
                    <p className='text-2xl max-w-[1000px] m-auto py-8 text-gray-500 text-center'>
                        Here are some of the questions our {currentLocation.name} customers always ask.
                        We provide this list to help you make the right decision for your home. We hope you find it helpful.
                    </p>
                    <div className='pt-4 space-y-4 mx-auto w-full max-w-[1000px]'>
                        <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' className='border border-gray-400 w-full shadow-2xl rounded-lg py-1 px-2' />
                    </div>

                    <div className='pt-4 space-y-4 mx-auto w-full max-w-[1000px]'>
                        {siteData.faqs.filter((faq) => (faq.question + faq.answer).toLocaleLowerCase().includes(search.toLocaleLowerCase())).map((faq) => (
                            <FAQCard
                                key={faq.id}
                                faq={faq}
                                isOpen={openFAQ === faq.id} // Check if this FAQ is open
                                onToggle={() => toggleFAQ(faq.id)} // Handle toggling
                            />
                        ))}
                    </div>
                </div>
            </section>
        </main>
    );
};

const FAQCard = ({ faq, isOpen, onToggle }) => {
    return (
        <div className='relative'>
            {/* FAQ Question */}
            <div
                onClick={onToggle}
                className={`${
                    isOpen ? "rounded-t-xl" : "rounded-xl"
                } bg-white z-50 flex items-center justify-between duration-300 hover:text-lg shadow-xl border border-gray-200 py-3 px-4 cursor-pointer`}
            >
                <h2 className='font-semibold text-lg'>{faq.question}</h2>
                {isOpen ? <FaChevronDown /> : <FaChevronRight />}
            </div>

            {/* FAQ Answer */}
            <div
                className={`${
                    isOpen ? "max-h-[500px] py-4 opacity-100" : "max-h-0 py-0 opacity-0"
                } bg-white z-0 duration-300 overflow-hidden shadow-xl border border-t-0 border-gray-200 px-4 rounded-b-xl`}
            >
                <p className='text-gray-600'>{faq.answer}</p>
            </div>
        </div>
    );
};

export default FAQ;
