import { useEffect } from 'react';
import Helmet from "react-helmet"
import About from "../components/About"
import { getCurrentLocation } from "../tools"
import theVan from '../assets/35266274_416574975484467_3661781944250662912_n.jpg'

const AboutPage = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px]">
            <Helmet>
                <meta charSet="utf-8" />
                <title>About - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="AA Richards Heating & Cooling has provided residential HVAC services since 1974 in Middlesex, Monmouth, and Ocean Counties." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}about`} />
            </Helmet>
            <h1 className='py-3 text-5xl text-center md:text-5xl font-bold'>About Us</h1>
            <img className='rounded-3xl mx-auto z-0 mb-[-100px]' src={theVan} alt="/" width={600} height={337} />
            <About />
        </main>
    )
}

export default AboutPage;