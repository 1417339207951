import { useEffect } from 'react';
import Helmet from "react-helmet"
import { Link as RouterLink } from 'react-router-dom';
import Paper from "../components/ui/Paper"
import siteData from "../data";

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return <main className="bg-gray-200 pb-10 pt-[100px] print:p-0">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy - A.A. Richards Heating, Cooling, & Plumbing</title>
            <meta name="description" content="At A.A. Richards, we value your privacy. This Privacy Policy details how we collect, use, and protect information from users of our website." />
            <link rel="canonical" href="https://aarichardshvac.com/privacy-policy" />
        </Helmet>
        <Paper>
        <div class="max-w-2xl print:max-w-[90%] mx-auto p-4 print:p-0">
            <h1 class="font-bold text-lg text-center py-2">Privacy Policy for A.A. Richards Heating, Cooling, & Plumbing</h1>
            <p class="pb-5">Last Updated: September 25, 2024</p>

            <section>
                <h2 class="font-bold pt-4">Introduction</h2>
                <p>At A.A. Richards Heating, Cooling, & Plumbing, we are committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and protection of information that we may receive from users of our website.</p>
            </section>

            <section>
                <h2 class="font-bold pt-4">Information We Collect</h2>
                <p>We want to assure you that we do not collect, store, or save any personal data from visitors to our website. This includes any information such as names, addresses, phone numbers, or email addresses. Our website is designed for informational purposes only, and we do not track or monitor user activity.</p>
            </section>

            <section>
                <h2 class="font-bold pt-4">Cookies</h2>
                <p>We do not use cookies on our website. For more information about our cookies policy, please visit our <RouterLink to="/cookies-policy" className='cursor-pointer text-blue-500'>Cookies Policy.</RouterLink></p>
            </section>

            <section>
                <h2 class="font-bold pt-4">Third-Party Links</h2>
                <p>Our website may contain links to other websites. A.A. Richards Heating, Cooling, & Plumbing is not responsible for the privacy practices or the content of such websites. We encourage users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
            </section>

            <section>
                <h2 class="font-bold pt-4">Security</h2>
                <p>We are committed to ensuring that your information is secure. Although we do not collect personal data, we implement reasonable security measures to protect our website and the information we do collect from unauthorized access or disclosure.</p>
            </section>

            <section>
                <h2 class="font-bold pt-4">Changes to This Privacy Policy</h2>
                <p>A.A. Richards Heating, Cooling, & Plumbing reserves the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any updates.</p>
            </section>

            
            <section class="pt-4">
                <h2 class="font-bold pt-4">Contact Us</h2>
                <p>A.A. Richards Heating, Cooling, & Plumbing</p>
                <p>{siteData.locations[0].address}</p>
                <p>Phone: {siteData.locations[0].salesPhone.display}</p>
            </section>
        </div>
        </Paper>
    </main>
}

export default PrivacyPolicy;