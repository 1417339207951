import { useEffect } from 'react';
import Helmet from "react-helmet"
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <main className="mt-[100px] min-h-[calc(100vh-100px)] flex items-center justify-center pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Oops! Page not found. It looks like we’ve hit a dead end. Let’s get you back on track—head to our homepage or try the search bar!" />
            </Helmet>

            <div>
                <h1 className="py-3 text-2xl md:text-4xl text-center font-bold">Page Not Found</h1>
                <button onClick={() => navigate(-1)} className="w-full py-1">Go Back</button>
            </div>
        </main>
    )
}

export default PageNotFound;