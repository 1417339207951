import { FaFacebook, FaHotjar } from 'react-icons/fa'
import { MdOutlinePlumbing, MdAir, MdCleaningServices } from "react-icons/md";
const siteData = {
    foundedon: 1974,
    yearsInBusiness: () => {
        return new Date().getFullYear() - siteData.foundedon
    },
    satisfiedHomeowners: 100000,
    services: [
        {
            name: "Heating",
            slug: "heating",
            icon: <FaHotjar size={40} className='h-6 text-indigo-600' />,
            description: "Need dependable heating solutions for your space? Find info and resources on furnaces, boilers, heat pumps, and more. Stay warm with our expert services!",
            buttonName: "Discover More About Heating Systems",
            buttonPath: "/heating",
            tags: ["heating", "heating system experts", "furnace installation", "boiler repair", "dependable heating solutions", "heating system maintenance"],
            category: "heating",
            subServices: []
        },
        {
            name: "Cooling",
            slug: "cooling",
            icon: <MdAir size={40} className='h-6 text-indigo-600' />,
            description: "A new AC unit can provide more efficient cooling, reduce energy bills, increase home value, and prevent costly repairs and peace of mind.",
            buttonName: "Explore More About Cooling Systems",
            buttonPath: "/cooling",
            tags: ["cooling", "Air Conditioning Installation", "Cooling System Maintenance", "Central Air Conditioning", "AC Repair Services"],
            category: "cooling",
            subServices: []
        },
        {
            name: "Plumbing",
            slug: "plumbing",
            icon: <MdOutlinePlumbing size={40} className='h-6 text-indigo-600' />,
            description: "Our team of experienced plumbing technicians is ready to handle any plumbing issue you may be experiencing from diagnosis to repair.",
            buttonName: "Learn More About Plumbing Systems",
            buttonPath: "/plumbing",
            tags: ["plumbing", "plumbing repair"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Water Heater Installation",
            slug: "water-heater-installation",
            icon: "",
            description: "Water heater installation ensures reliable hot water, improves energy efficiency, and prevents costly breakdowns for homes and businesses.",
            buttonName: "Learn More About Water Heater Installation",
            buttonPath: "/water-heater-installation",
            tags: ["heating", "plumbing", "installation", "water heater"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Furnace Repair",
            slug: "furnaces",
            icon: "",
            description: "Furnace units heat your home by distributing warm air. Regular maintenance ensures efficiency, longevity, and good air quality.",
            buttonName: "Learn More About Furnace Repair",
            buttonPath: "/furnaces",
            tags: ["heating", "repair", "furnaces"],
            category: "heating",
            subServices: []
        },
        {
            name: "Air Filters",
            slug: "filters",
            icon: "",
            description: "Filters in furnaces/AC units improve air quality, protect the system, and enhance efficiency. Types include fiberglass, pleated, and HEPA filters.",
            buttonName: "Learn More About Air Filters",
            buttonPath: "/filters",
            tags: ["heating", "cooling", "filters", "air"],
            category: "heating",
            subServices: []
        },
        {
            name: "Gas Line Installation",
            slug: "gas-line-installation",
            icon: "",
            description: "Ensure safety and efficiency with our expert gas line installation. Trust us for a seamless, reliable service that fuels your home!",
            buttonName: "Learn More About Gas Line Installation",
            buttonPath: "/gas-line-installation",
            tags: ["plumbing", "gas", "installation"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Sewer Line Replacement",
            slug: "sewer-line-replacement",
            icon: "",
            description: "Replacing your sewer line? Get peace of mind, enhanced flow, and durable solutions for your home today!",
            buttonName: "Learn More About Sewer Line Replacement",
            buttonPath: "/sewer-line-replacement",
            tags: ["plumbing", "piping", "installation"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Tankless Water Heaters",
            slug: "tankless-water-heaters",
            icon: "",
            description: "Upgrade to a tankless water heater for endless hot water, energy savings, and space efficiency. Enjoy comfort without compromise!",
            buttonName: "Learn More About Tankless Water Heaters",
            buttonPath: "/tankless-water-heaters",
            tags: ["plumbing", "piping", "installation"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Dryer Vent Cleaning",
            slug: "dryer-vent-cleaning",
            icon: "",
            description: "Boost dryer efficiency and prevent fire hazards with professional vent cleaning. Keep your home safe and your dryer running smoothly!",
            buttonName: "Learn More About Dryer Vent Cleaning",
            buttonPath: "/dryer-vent-cleaning",
            tags: ["cleaning"],
            category: "cleaning",
            subServices: []
        },
        {
            name: "Boilers",
            slug: "boilers",
            icon: "",
            description: "Upgrade to a high-efficiency boiler for consistent heating, lower energy bills, and long-lasting comfort. Reliable warmth, year-round!",
            buttonName: "Learn More About Boilers",
            buttonPath: "/boilers",
            tags: ["plumbing", "boilers", "piping", "installation"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Drain Clearing",
            slug: "drain-clearing",
            icon: "",
            description: "Clear your drains for smooth, hassle-free flow! Prevent clogs, avoid damage, and keep your plumbing in top shape with our expert drain clearing services.",
            buttonName: "Learn More About Drain Clearing",
            buttonPath: "/drain-clearing",
            tags: ["plumbing", "drain", "piping", "clearing"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Heat Pumps",
            slug: "heat-pumps",
            icon: "",
            description: "Switch to a heat pump for energy-efficient heating and cooling! Enjoy year-round comfort, lower bills, and a smaller carbon footprint.",
            buttonName: "Learn More About Heat Pumps",
            buttonPath: "/heat-pumps",
            tags: ["cooling", "heating"],
            category: "cooling",
            subServices: []
        },
        {
            name: "Water Heaters",
            slug: "water-heaters",
            icon: "",
            description: "Get reliable hot water with an energy-efficient water heater! Enjoy lower energy bills, consistent performance, and long-lasting comfort for your home.",
            buttonName: "Learn More About Water Heaters",
            buttonPath: "/water-heaters",
            tags: ["water heaters", "heating", "plumbing"],
            category: "plumbing",
            subServices: []
        },
        {
            name: "Heating Repair",
            slug: "heating-repair",
            icon: "",
            description: "Stay warm all winter with expert heating repair! Fast, reliable service to restore comfort and efficiency in your home. Don't wait—get cozy again today!",
            buttonName: "Learn More About Heating Repair",
            buttonPath: "/heating-repair",
            tags: ["heating", "heating repair", "home heating"],
            category: "heating",
            subServices: []
        }
    ],
    locations: [
        {
            name: "Tom's River",
            slug: "toms-river",
            title: "AA Richards HVAC - Tom's River",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            technicalSupportPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "835 Mantoloking Rd Brick Township, NJ 08723",
        },
        {
            name: "Matawan",
            slug: "matawan",
            title: "AA Richards HVAC - Matawan",
            description: "",
            salesPhone: {
                display: "(732) 290 8466",
                value: "+17322908466"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "84 Main Street Matawan, NJ 07747",
        },
        {
            name: "Keyport",
            slug: "keyport",
            title: "AA Richards HVAC - Keyport",
            description: "",
            salesPhone: {
                display: "(732) 335 5859",
                value: "+17323355859"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Belmar",
            slug: "belmar",
            title: "AA Richards HVAC - Belmar",
            description: "",
            salesPhone: {
                display: "(732) 681 8282",
                value: "+17326818282"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Howell",
            slug: "howell",
            title: "AA Richards HVAC - Howell",
            description: "",
            salesPhone: {
                display: "(732) 364 4343",
                value: "+17323644343"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Forked River",
            slug: "forked-river",
            title: "AA Richards HVAC - Forked River",
            description: "",
            salesPhone: {
                display: "(609) 693 9777",
                value: "+16096939777"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Brick",
            slug: "brick",
            title: "AA Richards HVAC - Brick",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Red Bank",
            slug: "red-bank",
            title: "AA Richards HVAC - Red Bank",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Long Branch",
            slug: "long-branch",
            title: "AA Richards HVAC - Long Branch",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Colts Neck",
            slug: "colts-neck",
            title: "AA Richards HVAC - Colts Neck",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        },
        {
            name: "Holmdel",
            slug: "holmdel",
            title: "AA Richards HVAC - Holmdel",
            description: "",
            salesPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            emergencyPhone: {
                display: "(732) 281 0003",
                value: "+17322810003"
            },
            address: "",
        }
    ],
    
    faqs: [
        {
            id: 1,
            question: "How often do air filters need to be changed?",
            answer: "It varies depending on the type of filter you have. If you have cheaper, disposable filters, we recommend changing them every 30 days. If you have pleated, or poly, filters, we recommend changing them every 90 days. Media filters or CleanEffects filters can last a little longer and should be replaced about once a year.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 2,
            question: "Do bigger air conditioning units perform better?",
            answer: "Not necessarily. It is important to purchase an air conditioner that is the right size for your home. If you buy an AC unit that’s too big, it will cool your home faster, but it will also be less efficient and will not eliminate humidity well enough. It will have short-run cycles. While it will cool the air quickly, it will shut off before it blows enough air across the indoor coil to drain water from your system. This will leave too much moisture in the air, which can lead to mold and mildew in your home. Your system starting and stopping more often may cause it to use more energy and wear down faster. Longer run cycles are more efficient.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 3,
            question: "How long does HVAC equipment last?",
            answer: "HVAC systems generally last 10 to 15 years. However, your system becomes less efficient and performs worse as it gets older. Therefore, after about 10 years, you may want to start looking for a replacement. Even if you are not having any major problems, modern HVAC systems will save you money on your energy bills because they are more efficient. Plus, they generally come with 10-year warranties, so you won’t need to worry about replacing them for quite a while.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 4,
            question: "If I have an older system, can I save money by just replacing the outdoor unit?",
            answer: "Probably not. Older systems use Freon R-22, which has been phased out and replaced with Freon R-410A, also known as Puron. Puron is more environmentally friendly and more efficient. In addition, the minimum SEER rating or efficiency that we can use today is 13 SEER (older systems are 10 SEER and below). The refrigerant and efficiency rating of your indoor coil and outdoor unit must be the same, so you have to replace the whole system at once.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 5,
            question: "Do I need to clean my AC coil?",
            answer: "we found that clean air conditioner condensers often perform better than dirty ones. The change in condenser coil heat transfer performance ranged from a 7 percent increase to a 7 percent decrease for the coils we tested.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 6,
            question: "Why dirty ducts are bad?",
            answer: "Dirty ducts are so bad because they harm your personal health and lower your home’s efficiency. In the realm of personal health, the contaminants in dirty ducts can trigger allergy and respiratory issues. When it comes to efficiency, dirty ducts can impede the flow of your home’s indoor air and cause your HVAC systems to work harder for average results. If you want to breathe better and get better IAQ that costs less, we suggest duct cleaning.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 7,
            question: "What is duct cleaning?",
            answer: "Duct cleaning is the process of our professionals coming in and vacuuming the buildup from your ducts. Our technicians are highly trained. This means we can do great work fast without compromising the integrity of your home. We’re committed to treating your home how we would our mother’s. It’s why we have a better-than-we-found-it guarantee.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 8,
            question: "Why expert duct cleaning is necessary?",
            answer: "You need an expert to clean your ducts because we have the tools and experience. It's necessary to clean your ducts because anyone’s ducts can get dirty. You need to keep your ducts pristine so you can lower your energy bills and breathe fresh air.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 9,
            question: "Why do I need a system with matched components?",
            answer: "Matched systems ensure maximum performance, maximum efficiency, and maximum comfort. A matched system will maintain proper cycle times and manage humidity properly for your home. In addition, a matched system will prevent you from paying too much for your energy bills. Your system will run at peak efficiency, with no energy wasted due to improper size.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 10,
            question: "How often should I have my air conditioning unit serviced?",
            answer: "We recommend having your air conditioner serviced once a year. This will save you on energy and repair costs, keep your system running smoothly and efficiently, and make it last longer. The longer you put off AC maintenance, the worse your unit will perform, and the more likely it is that it will break down. Trying to save money by delaying maintenance may backfire by forcing you to pay more on energy bills, and potentially, on repairs if a serious issue arises with your system.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 11,
            question: "How can I improve the air quality in my home?",
            answer: "The first step to improving your air quality is to prevent unwanted air from entering your home. To do this, seal your duct system. Then you can focus on either cleaning your existing system or installing a more efficient air filtration system.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 12,
            question: "What is duct cleaning?",
            answer: "Duct cleaning includes cleaning the various components of HVAC forced air systems, including the air ducts and registers, grilles and diffusers, heat exchangers, coils, fan motors, fan housing, and the air handling unit housing. Dust, pollen, and other debris may build up in these components if they are not properly maintained.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 13,
            question: "What are some tips for lowering my energy costs?",
            answer: "It depends on your specific home. In some cases, adding insulation will help. In some cases, replacing your HVAC system or other appliances will help. Sometimes replacing your windows can help. Take your entire home into account to figure out what makes the most sense for you.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 14,
            question: "What are some ways I could keep my home cool more efficiently?",
            answer: "There are plenty of ways to improve the cooling efficiency in your home. First, you could replace your existing air conditioner with a newer, more efficient one. This will significantly lower your energy bills and cool your home much more effectively. Schedule a consultation so we can help you find a unit that fits your home. If you aren’t ready to upgrade your air conditioner, you can improve efficiency by setting your thermostat to a higher temperature. During the summer, set your thermostat to 75 degrees or higher. Every degree counts: just 1 degree below 75 degrees will cause your energy bill to go up 3 to 5%. Another small way to increase your home’s cooling efficiency is to avoid blocking vents. Move any furniture or curtains to evenly distribute air throughout your home. However, if there are rooms in your home that you rarely use, consider closing the vents to avoid wasting energy. Additionally, you can install ceiling fans. The U.S. Department of Energy says that a single ceiling fan “will allow you to raise the thermostat setting about 4 degrees with no reduction in comfort.” As we already mentioned, every degree will save you money on your energy bill. Finally, make sure to get annual maintenance from our licensed technicians to ensure that your system is running as efficiently as possible, and to fix any potential issues.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 15,
            question: "How can I heat my home more efficiently?",
            answer: "The most effective, but most expensive solution is to replace your furnace with a newer, more efficient furnace. Modern high-efficiency furnaces could save you 25% on your energy bills, which means the expense of purchasing a new furnace should pay for itself fairly quickly. Just as you can set your thermostat to a higher temperature for greater efficiency in the summer, lowering the temperature on your thermostat for at least a portion of the day can save you money on your energy bill in the winter. Just like in the summer, avoid blocking your vents to ensure that heat is evenly distributed throughout the house. Save energy by closing vents in rooms that you rarely use. While it may seem counter-intuitive, ceiling fans can help heat your home in the winter, if the blades spin clockwise. Therefore, install ceiling fans for improved energy efficiency in both the summer and winter. Finally, annual furnace maintenance will ensure that your furnace is running smoothly and efficiently and catch any potential issues.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 16,
            question: "What is a heat pump?",
            answer: "A heat pump is a combination of a heating and air conditioning systems. You can install a heat pump with a variety of indoor systems, including all-electric and dual-fuel, to keep you comfortable all year. In the winter, a heat pump takes the heat from the cold outside air and brings it inside to heat your home. However, sometimes this is not enough heat for your desired warmth, so generally, people use an electric heater or gas furnace to supplement the heat pump.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 17,
            question: "Why should I use equipment with variable-speed fans?",
            answer: "Variable speed equipment is typically more efficient and creates a more comfortable environment for you. Traditional systems only read temperature and only use a one-speed fan. Variable airflow systems have variable-speed fans that are more efficient because they run at lower speeds when higher speeds are not necessary. They also read both temperature and humidity. Therefore, these systems remove more moisture than traditional systems do, increasing your comfort when it is hotter outside. Finally, variable-speed fans are typically quieter because they are able to run more slowly when appropriate.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 18,
            question: "What is a two-stage compressor?",
            answer: "As the name suggests, a two-stage compressor operates in two stages. Some two-stage compressors have one compressor with two stages, while others have two separate compressors. In either case, the unit runs in the lower stage, which is less than full capacity, the majority of the time. However, on the hottest days, it utilizes stage two, which operates at full capacity. Since the system is not running at full capacity all the time, it is quite efficient, which will save you on energy bills. An alternative to two-stage compressors is variable-speed compressors, which run at even lower capacity, meaning greater efficiency.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 19,
            question: "What is a dual-fuel system?",
            answer: "A dual-fuel system involves a heat pump, an indoor coil, and a gas furnace working together to heat your home. The heat pump does most of the work, but the furnace is used as a backup. If the temperature drops below a certain point, the heat pump will not be able to meet your needs and the furnace will take over. This is a very efficient system that may save you energy. Schedule a consultation to decide if a dual-fuel system is the best option for your home.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 20,
            question: "What should I set my thermostat to?",
            answer: "In terms of energy efficiency, the general rule is, that in summer: the higher, the better, and in winter: the lower, the better. However, the point of air conditioners and furnaces is to keep your home comfortable. Therefore, you need to find the right balance for you between comfort and energy efficiency. For energy efficiency and lower bills, we recommend keeping your thermostat around 78 degrees in the summer and 68 degrees in the winter. In addition to your personal preferences, your home’s efficiency in terms of insulation, ducts, air filters, ceiling fans, blocked vents, and other factors can affect the optimal temperature for your home.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 21,
            question: "Is R-22 refrigerant being discontinued?",
            answer: "Yes. R-22 is no longer allowed in new equipment due to environmental regulations. Since many existing residential air conditioning units still use it, R-22 is still produced. However, it is gradually being phased out. By January 1, 2020, the production of R-22 will be reduced by 99.5%. It will become expensive and difficult to find. Therefore, if your system uses R-22 refrigerant, you may want to consider switching to a newer system.",
            link: {
                name: "",
                url:""
            }
        },
        {
            id: 22,
            question: "What can I do if my system doesn't work well in certain rooms?",
            answer: "A little variation in temperature between rooms is normal, especially if you only have one thermostat. However, it can also be a sign of poorly designed ducts or air filtration issues. Schedule a free consultation so we can identify the problem and offer solutions to fix it.",
            link: {
                name: "",
                url:""
            }
        }
    ],
    socialMedia: [
        {
            name: "Facebook",
            icon: <FaFacebook size={30}/>,
            url: "https://www.facebook.com/p/AA-Richards-Heating-Cooling-and-Plumbing-100063468763889/?locale=website"
        }
    ],
}



export default siteData