import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../tools"
import Hero from '../components/Hero';
import About from '../components/About';
import Support from '../components/Support';
import AllInOne from '../components/AllInOne';

import guarantee_service_Free_Estimates from '../assets/images/service-guarantees/guarantee-service-Free-Estimates.webp'
import guarantee_service_Response_Time from '../assets/images/service-guarantees/guarantee-service-Response-Time.webp'
import guarantee_service_Warranty from '../assets/images/service-guarantees/guarantee-service-Warranty.webp'

const Home = () => {
    const currentLocation = getCurrentLocation()
    
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Free HVAC Quotes. Quick Service: New Jersey Heating, & AC Repair. Complete Heating & Cooling Services in Middlesex, Monmouth & Ocean County New Jersey." />
                <meta name="keywords" content="Free HVAC Quotes, Heating Repair, Cooling Repair, AC Company" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}` : ""}`} />
            </Helmet>
            <Hero />

            <div className='mt-[180px] md:mt-[100px]'>
                    <h2 className='py-3 text-4xl text-center font-bold'>Service Guarantees</h2>
                    <div className='flex justify-between w-full max-w-[800px] mx-auto'>
                        <div>
                            <img src={guarantee_service_Free_Estimates} alt='Free estimates' width={200} height={200} />
                        </div>

                        <div>
                            <img src={guarantee_service_Response_Time} 
                            alt='Financing available for qualified candidates' width={200} height={200} />
                        </div>

                        <div>
                            <img src={guarantee_service_Warranty} alt='Service contracts' width={200} height={200} />
                        </div>
                    </div>
                </div>

            <About />
            <Support />
            {/* <ContactForm /> */}
            <AllInOne />
            {/* <Pricing /> */}
        </>
    )
}

export default Home