import React from 'react'
import siteData from "../data"
import LocationRouterLink from "../components/LocationRouterLink"
import twoVansFall from '../assets/twoVansFall.webp'
import { getCurrentLocation } from "../tools"

const Hero = () => {
    const currentLocation = getCurrentLocation()

  return (
    <div name='home' className='w-full pt-10 h-screen bg-zinc-200 flex flex-col'>
        <div className='m-auto'>
            <div className='max-w-[1240px] flex justify-between md:flex-row flex-col-reverse'>

                <div className='flex flex-col justify-center w-full bg-white rounded-xl md:mt-0 mt-[-100px] md:bg-transparent px-2 py-8' >
                    <p className='text-2xl'>A.A. Richards Heating, Cooling, & Plumbing</p>
                    <h1 className='py-3 text-5xl md:text-4xl lg:text-6xl font-bold'>Providing Comfort Since 1974</h1>
                    <p className='text-2xl'>Serving Middlesex, Monmouth & Ocean County</p>
                    {<a href={"tel:" + currentLocation.salesPhone.value} 
                    className='py-3 button px-6 text-lg w-full sm:max-w-[300px] my-4'>Call our {currentLocation.name} Office</a>}
                </div>

                <div className='pt-[40px]'>
                    <img className='w-full rounded-3xl -z-10' src={twoVansFall} alt="/" />
                </div>


            </div>

            <div className='flex flex-col z-50 mx-auto py-5 w-full my-10 max-w-[800px]
                bg-zinc-200 border border-slate-300 rounded-xl text-center shadow-xl'>
                <p>Main Services</p>
                <div className='flex justify-between flex-wrap px-4'>
                    {siteData.services.slice(0, 3).map((service) => (
                        <LocationRouterLink to={service.buttonPath} key={service.name}
                            currentLocation={currentLocation}
                            className='flex cursor-pointer text-xl px-4 py-2 text-slate-500'>
                            {service.icon}{" "}{service.name}</LocationRouterLink>
                    ))}
                </div>
            </div>
        </div>

    </div>
  )
}

export default Hero