import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import heatingRepair from "../../assets/images/l-chris-working-16.9-573x322.webp"

const HeatingRepair = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Heating Repair")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Heating Repair - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Stay warm all winter with expert heating repair! Fast, reliable service to restore comfort and efficiency in your home. Don't wait—get cozy again today!" />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}heating-repair`} />
            </Helmet>
            <Showcase info={{
                title: "Fast, Reliable Heating Repair!",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Stay warm all winter with expert heating repair! Fast, reliable service to restore comfort and efficiency in your home. Don't wait—get cozy again today!",
                image: {
                    src: heatingRepair,
                    alt: "",
                    width: 573,
                    height: 322
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>You don’t have to tough it out when you notice something off with your furnace. You deserve precise and effective repair work. We’re the team who can provide it.</p>
        </main>
    )
}

export default HeatingRepair;