import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import waterHeaters from "../../assets/images/Water-Heaters-600x338.webp"

const WaterHeaters = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Water Heaters")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Water Heaters - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Get reliable hot water with an energy-efficient water heater! Enjoy lower energy bills, consistent performance, and long-lasting comfort for your home." />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}water-heaters`} />
            </Helmet>
            <Showcase info={{
                title: "Get Reliable Hot Water, Energy Savings!",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Get reliable hot water with an energy-efficient water heater! Enjoy lower energy bills, consistent performance, and long-lasting comfort for your home.",
                image: {
                    src: waterHeaters,
                    alt: "",
                    width: 600,
                    height: 338
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>If your water heater hasn’t been keeping up with demand and you’ve already tried repairing it, it may be time for an upgrade. At AA Richards Heating, Cooling & Plumbing, our expert plumbers have been servicing New Jersey water heaters since 1992.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We always stay up to date with the best technology for all budgets. Give us a call to learn more and schedule your free, in-home estimate to determine which water heater is right for you.</p>
        </main>
    )
}

export default WaterHeaters;