import Helmet from "react-helmet"
import { useEffect } from 'react';
import { getCurrentLocation } from "../tools"
// import ContactForm from "../components/ContactForm"
import siteData from "../data"

const ContactUs = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Reach A.A. Richards for HVAC services in Tom's River. Our team is ready to assist with installations and repairs. We also have other office locations!" />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}contactus`} />
            </Helmet>
            <h1 className='py-3 text-2xl md:text-4xl text-center font-bold'>Contact A.A. Richards In {currentLocation.name} Today</h1>

            <h2 className='py-3 text-xl md:text-3xl text-center font-bold'>Other Office Locations</h2>
            <div className="w-full max-w-[1000px] mx-auto grid md:grid-cols-4 grid-cols-2">
            {siteData.locations.slice(0,6).map((location) => (
                <div key={location.name} className="text-center">
                    <p>{location.name}</p>
                    <a href={"tel:" + location.salesPhone.value} 
                    className='py-3 px-6 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{location.salesPhone.display}</a>
                </div> 
                ))}
            </div>
            {/* <ContactForm /> */}
        </main>
    )
}

export default ContactUs