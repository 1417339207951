import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import furnace from "../../assets/images/gas-furnace-847x612.webp"

const Furnaces = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Furnaces")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Furnaces - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Furnace units heat your home by distributing warm air. Regular maintenance ensures efficiency, longevity, and good air quality." />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}furnaces`} />
            </Helmet>
            <Showcase info={{
                title: "Every Furnace Requires Regular Maintenance",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Furnace units heat your home by distributing warm air. Regular maintenance ensures efficiency, longevity, and good air quality.",
                image: {
                    src: furnace,
                    alt: "",
                    width: 847,
                    height: 612
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>One of the best ways to improve your home’s air quality is with an air filter and air purifier. If you’re looking for someone who can make your air filtration system or air purification system great, then give us a call.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We’re experts in everything whole home air purification and air filtration related. It’s why you come to us for everything including air purification and air filtration maintenance, installation, repair, and replacement.</p>
        </main>
    )
}

export default Furnaces;