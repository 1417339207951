import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import Showcase from '../../components/ui/Showcase';
import waterHeaters from "../../assets/images/Water-Heaters-600x338.webp"

const WaterHeaterInstallation = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Water Heater Installation")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Water Heater Installation - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Water heater installation ensures reliable hot water, improves energy efficiency, and prevents costly breakdowns for homes and businesses." />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}water-heater-installation`} />
            </Helmet>
            <Showcase info={{
                title: "Water Heater Installation for Homes & Businesses",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Water heater installation ensures reliable hot water, improves energy efficiency, and prevents costly breakdowns for homes and businesses.",
                image: {
                    src: waterHeaters,
                    alt: "",
                    width: 600,
                    height: 338
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>At A.A. Richards Heating, Cooling, & Plumbing, we understand the importance of hot water in your daily life. That's why we offer professional and reliable water heater installation services to ensure that you have a steady supply of hot water when you need it.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Our team of experienced plumbers can handle the installation of both gas and electric water heaters, and we can also assist with the installation of tankless water heaters for those who want an endless supply of hot water.</p>
        </main>
    )
}

export default WaterHeaterInstallation