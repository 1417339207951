import { useEffect, useState } from "react";
import siteData from "./data"

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );
    current && observer?.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  return isVisible;
};

export const getCurrentLocation = () => {
  return siteData.locations[siteData.locations.findIndex((location) => location.slug === window.location.pathname.split("/")[1])] || {
    name: "Tom's River",
    salesPhone: {
      display: "(732) 281 0003",
      value: "+17322810003"
    }
  }
};

export const getService = (name) => {
  return siteData.services[siteData.services.findIndex((service) => service.name === name)]
};