import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import siteData from "../../data";
import Showcase from '../../components/ui/Showcase';
import Furnace from "../../assets/images/Furnace-862x539.webp"

const Heating = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Heating")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Heating - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Need dependable heating solutions for your space? Find info and resources on furnaces, boilers, heat pumps, and more. Stay warm with our expert services!" />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}heating`} />
            </Helmet>
            <Showcase info={{
                title: "We Are The Experts In Heating Systems",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Need dependable heating solutions for your space? Find info and resources on furnaces, boilers, heat pumps, and more. Stay warm with our expert services!",
                image: {
                    src: Furnace,
                    alt: "",
                    width: 862,
                    height: 539
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>We are a heating and cooling company that has been serving the community for over {siteData.yearsInBusiness()} years. We take pride in providing top-notch service to our customers and ensuring their comfort in their homes and businesses.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>At A.A. Richards Heating, Cooling, & Plumbing, we specialize in providing heating services to our customers. Whether you need a new heating system installed, your existing heating system repaired, or routine maintenance, we have you covered. Our team of expert technicians is highly trained and experienced in working with all types of heating systems, including furnaces, boilers, heat pumps, and more.</p>
        </main>
    )
}

export default Heating;