import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation } from "../tools"

const Jobs = () => {
    const currentLocation = getCurrentLocation()
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Jobs - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Join A.A. Richards HVAC, where your skills are valued! Enjoy career growth and opportunities to transfer experience across departments." />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}jobs`} />
            </Helmet>
            <h1 className='py-3 text-3xl text-center md:text-4xl font-bold'>Employment Opportunities</h1>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Join the professional team at A.A. Richards HVAC, where your contributions are recognized, and your skills are truly valued! As a growing company, we offer endless opportunities for career growth, including the ability to transfer your experience across different departments.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
        </main>
    )
}

export default Jobs;