import { useEffect } from 'react';
import Helmet from "react-helmet"
import Paper from "../components/ui/Paper"
import siteData from "../data";
const CookiesPolicy = () => {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return <main className="bg-gray-200 pb-10 pt-[100px] print:p-0">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Cookies Policy - A.A. Richards Heating, Cooling, & Plumbing</title>
            <meta name="description" content="At A.A. Richards Heating, Cooling, & Plumbing, we are committed to protecting your privacy. This Cookies Policy outlines our use of cookies on our website." />
            <link rel="canonical" href="https://aarichardshvac.com/cookies-policy" />
        </Helmet>
        <Paper>
            <div class="max-w-2xl print:max-w-[90%] mx-auto p-4 print:p-0">
                <h1 class="font-bold text-lg text-center py-2">Cookies Policy for A.A. Richards Heating, Cooling, & Plumbing</h1>
                <p class="pb-5">Last Updated: September 25, 2024</p>

                <p>At A.A. Richards Heating, Cooling, & Plumbing, we are committed to protecting your privacy. This Cookies Policy outlines our use of cookies on our website.</p>

                <h2 class="font-bold pt-4">No Use of Cookies</h2>
                <p>We do not use cookies on our website. This means we do not track, collect, or store any personal data via cookies or other similar technologies while you browse our site.</p>

                <h2 class="font-bold pt-4">What Are Cookies?</h2>
                <p>Cookies are small files that websites often store on your device to help enhance your browsing experience. They can track your activity on the site, store login information, or remember your preferences. However, as we do not use cookies, your browsing activity on our site remains private and untracked.</p>

                <h2 class="font-bold pt-4">External Links</h2>
                <p>Please note that our website may contain links to third-party websites. These websites may use cookies and collect personal data. We recommend reviewing the privacy policies of these external websites for more information.</p>

                <h2 class="font-bold pt-4">Changes to This Policy</h2>
                <p>We reserve the right to update this Cookies Policy at any time. Any changes will be reflected on this page.</p>

                <section class="pt-4">
                    <h2 class="font-bold pt-4">Contact Us</h2>
                    <p>A.A. Richards Heating, Cooling, & Plumbing</p>
                    <p>{siteData.locations[0].address}</p>
                    <p>Phone: {siteData.locations[0].salesPhone.display}</p>
                </section>
            </div>
        </Paper>
    </main>
}

export default CookiesPolicy;