import { useEffect } from 'react';
import Helmet from "react-helmet"
import { getCurrentLocation, getService } from "../../tools"
import siteData from "../../data";
import Showcase from '../../components/ui/Showcase';
import gasLineInstallation from "../../assets/images/gas-line-installation-nj-800x912.webp"

const GasLineInstallation = () => {
    const currentLocation = getCurrentLocation()
    const service = getService("Gas Line Installation")

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
      }, []);

    return (
        <main className="mt-[100px] pb-20">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gas Line Installation - A.A. Richards Heating, Cooling, & Plumbing</title>
                <meta name="description" content="Ensure safety and efficiency with our expert gas line installation. Trust us for a seamless, reliable service that fuels your home!" />
                <meta name="keywords" content={service.tags.join(", ")} />
                <link rel="canonical" href={`https://aarichardshvac.com/${currentLocation.slug ? `${currentLocation.slug}/` : ""}gas-line-installation`} />
            </Helmet>
            <Showcase info={{
                title: "Let Us Install Your Gas Line",
                subTitle: "A.A. Richards Heating, Cooling, & Plumbing",
                description: "Ensure safety and efficiency with our expert gas line installation. Trust us for a seamless, reliable service that fuels your home!",
                image: {
                    src: gasLineInstallation,
                    alt: "",
                    width: 800,
                    height: 912
                }
            }} />
            
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Call our {currentLocation.name} office at <a href={"tel:" + currentLocation.salesPhone.value} className='py-3 text-lg text-blue-500 font-bold sm:w-[60%] my-4'>{currentLocation.salesPhone.display}</a> to get started.</p>
            <h2 className='py-3 text-2xl text-center md:text-3xl font-bold'>Why Choose A.A. Richards Heating, Cooling, & Plumbing?</h2>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>At A.A. Richards Heating, Cooling, & Plumbing, we understand that a properly installed gas line is crucial for the safety and efficiency of your home or business. That's why we offer professional gas line installation services to ensure that you have a safe and reliable source of gas for all your needs.</p>
            <p className='text-2xl max-w-[1000px] text-center m-auto py-6 text-gray-500'>Our team of experienced plumbers are trained and certified in gas line installation, and we use only the highest quality materials to ensure that your gas line is installed to the highest industry standards. We understand the importance of a safe and efficient gas line, and we take great care to ensure that your installation is completed in a timely and professional manner.</p>
        </main>
    )
}

export default GasLineInstallation