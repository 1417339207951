import { getCurrentLocation } from "../../tools"

const Showcase = ({ info }) => {
    const currentLocation = getCurrentLocation()

    return (
    <section className='grid grid-cols-1 lg:grid-cols-2 max-w-[1240px] m-auto'>
        <div className='flex flex-col max-w-[800px] justify-center items-center mx-auto lg:items-start w-full px-2 py-8' >
            <p className='text-2xl'>{info.subTitle}</p>
            <h1 className='py-3 text-5xl text-center lg:text-left md:text-6xl font-bold'>{info.title}</h1>
            <p className='text-2xl max-w-[600px]'>{info.description}</p>
            {<a href={"tel:" + currentLocation.salesPhone.value} 
            className='py-3 button px-6 text-lg sm:w-[60%] my-4'>Call our {currentLocation.name} Office</a>}
        </div>
        <div className='hidden lg:block pt-[40px]'>
            <img src={info.image.src} className='mx-auto w-auto rounded-3xl max-h-[400px]' alt={info.image.alt || ""} 
                width={info.image.width} height={info.image.heighth} />
        </div>
    </section>
    )
}

export default Showcase;